<template>
  <div>
    <q-form ref="editForm">
      <div class="row">
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
          <c-card title="화학사고 예방관리계획서 기본정보" class="cardClassDetailForm">
            <template slot="card-button">
              <q-btn-group outline >
                <c-btn v-if="editable && popupParam.koraDocumentId" label="삭제" icon="delete_forever" @btnClicked="removeData" />
                <c-btn
                  v-if="editable"
                  :url="saveUrl"
                  :isSubmit="isSave"
                  :param="data"
                  :mappingType="mappingType"
                  label="저장"
                  icon="save"
                  @beforeAction="saveData"
                  @btnCallback="saveCallback" />
              </q-btn-group>
            </template>
            <template slot="card-detail">
              <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
                <c-plant
                  required
                  :editable="editable"
                  type="edit"
                  name="plantCd"
                  v-model="data.plantCd">
                </c-plant>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                <c-text
                  required
                  :editable="editable"
                  label="제목"
                  name="documentTitle"
                  v-model="data.documentTitle">
                </c-text>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
                <c-select
                  required
                  type="edit"
                  codeGroupCd="KORA_SUBMIT_TYPE_CD"
                  itemText="codeName"
                  itemValue="code"
                  name="koraSubmitTypeCd"
                  label="제출구분"
                  v-model="data.koraSubmitTypeCd"
                ></c-select>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
                <c-select
                  required
                  type="edit"
                  codeGroupCd="KORA_SUBMIT_LEVEL_CD"
                  itemText="codeName"
                  itemValue="code"
                  name="koraSubmitLevelCd"
                  label="작성수준"
                  v-model="data.koraSubmitLevelCd"
                ></c-select>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
                <c-datepicker
                  required
                  :editable="editable"
                  label="제출일"
                  name="documentDate"
                  v-model="data.documentDate">
                </c-datepicker>
              </div>
              <div class="col-12">
                <c-textarea
                  :editable="editable"
                  type="editor"
                  label="내용"
                  name="documentContents"
                  :editheight="20"
                  v-model="data.documentContents">
                </c-textarea>
              </div>
            </template>
          </c-card>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
          <c-card title="첨부파일" class="cardClassDetailForm">
            <template slot="card-detail">
              <div class="col-sm-12 col-md-12 col-lg-12">
                <c-upload 
                  :attachInfo="attachInfo"
                  :editable="editable"
                  label="첨부파일">
                </c-upload>
              </div>
            </template>
          </c-card>
        </div>
      </div>
      <c-dialog :param="popupOptions"></c-dialog>
    </q-form>
  </div>
</template>

<script>
import { uid } from 'quasar';
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'iso-document-detail',
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        koraDocumentId: '',
      }),
    },
  },
  data() {
    return {
      attachInfo: {
        isSubmit: '',
        taskClassCd: 'KORA_DOCUMENT',
        taskKey: '',
      },
      saveUrl: transactionConfig.mdm.kora.document.insert.url,
      mappingType: 'POST',
      insertUrl: '',
      regUserName: '',
      updateUrl: '',
      deleteUrl: '',
      editable: true,
      isSave: false,
      isdelete: false,
      data: {
        koraDocumentId: '',  // 화학사고예방관리계획서 일련번호
        plantCd: null,  // 사업장코드
        koraSubmitTypeCd: null,  // 화학사고예방관리계획서 제출 구분코드
        koraSubmitLevelCd: null,  // 화학사고예방관리계획서 작성수준 코드
        documentDate: '',  // 화학사고예방관리계획서 제출 일자
        documentTitle: '',  // 화학사고예방관리계획서 제목
        documentContents: '',  // 화학사고예방관리계획서 내용
        regUserId: '',
      },
      updateMode: false,
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        width: '90%',
        top: '10px',
        param: {},
        closeCallback: null,
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      // url setting
      this.data.documentDate = this.$comm.getToday();
      
      this.detailUrl = selectConfig.mdm.kora.document.get.url;
      this.insertUrl = transactionConfig.mdm.kora.document.insert.url;
      this.updateUrl = transactionConfig.mdm.kora.document.update.url;
      this.deleteUrl = transactionConfig.mdm.kora.document.delete.url;
      this.getDetail();
    },
    getDetail() {
      if (this.popupParam.koraDocumentId) {
        this.$http.url = this.detailUrl;
        this.$http.param = {koraDocumentId: this.popupParam.koraDocumentId};
        this.$http.type = 'GET';
        this.$http.request((_result) => {
          this.data = _result.data;
          // 상세팝업 최초작성/최근수정 정보 노출
          this.$emit('setRegInfo', _result.data)
          this.$set(this.attachInfo, 'taskKey', this.popupParam.koraDocumentId)
        },);
      }
    },
    saveData() {
      if (this.popupParam.koraDocumentId) {
        this.saveUrl = this.updateUrl;
        this.mappingType = 'PUT';
      } else {
        this.saveUrl = this.insertUrl;
        this.mappingType = 'POST';
      }
      this.$refs['editForm'].validate().then(_result => {
        if (_result) {
          window.getApp.$emit('CONFIRM', {
            title: '확인',
            message: '저장하시겠습니까?',
            
            type: 'info', // success / info / warning / error
            // 확인 callback 함수
            confirmCallback: () => {
              this.data.regUserId = this.$store.getters.user.userId;
              this.data.chgUserId = this.$store.getters.user.userId;

              this.isSave = !this.isSave;
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    removeData() {
      window.getApp.$emit('CONFIRM', {
        title: '확인',
        message: '삭제하시겠습니까?',
        
        type: 'warning', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          this.$http.url = this.$format(this.deleteUrl, this.popupParam.koraDocumentId);
          this.$http.type = 'DELETE';
          this.$http.request(() => {
            this.isdelete = !this.isdelete;
            window.getApp.$emit('APP_REQUEST_SUCCESS');
            this.$emit('closePopup');
          },);
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });
    },
    saveCallback(result) {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.popupParam.koraDocumentId = result.data.koraDocumentId;
      this.$set(this.attachInfo, 'taskKey', this.popupParam.koraDocumentId)
      this.$set(this.attachInfo, 'isSubmit', uid())
      this.getDetail();
    },
  }
};
</script>
